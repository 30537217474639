require('@rails/ujs').start();
require('jquery');
require('bootstrap');
require('popper.js');
require('icheck-bootstrap');
require('overlayscrollbars/css/OverlayScrollbars.css');
require('overlayscrollbars/js/jquery.overlayScrollbars.js');
require('@fortawesome/fontawesome-free/css/all');
require('flag-icon-css/css/flag-icon.css');
require('../src/application.css');
require('../src/js/admin.js');
require.context('../src/images', true);
